<template>
  <!-- 菜单管理 -->
  <div class="main">
    <div class="condition-filtrate-box">
      <div class="left-item">
        <el-input class="select-width id-code-width" size="medium" v-model="queryForm.orgName" placeholder="请输入部门名称" />
        <el-select clearable class="select-width" size="medium" v-model="queryForm.orgType" placeholder="部门类型">
          <el-option @change="depatmentchoose()" v-for="(item,v) in orgTypeList" :key="v" :label="item.label"
            :value="Number(item.value)">
          </el-option>
        </el-select>

        <el-select clearable class="select-width" size="medium" v-model="queryForm.homeIsolation" placeholder="居家隔离业务">
          <el-option v-for="(item,v) in homelist" :key="v" :label="item.label" :value="item.value">
          </el-option>
        </el-select>

        <el-select clearable class="select-width" size="medium" v-model="queryForm.transshipmentDispatching"
          placeholder="转运调度业务">
          <el-option v-for="(item,v) in dispatthlist" :key="v" :label="item.label" :value="Number(item.value)">
          </el-option>
        </el-select>

        <el-select clearable class="select-width" size="medium" v-model="queryForm.transshipmentTask"
          placeholder="转运任务业务">
          <el-option v-for="(item,v) in assignlist" :key="v" :label="item.label" :value="Number(item.value)">
          </el-option>
        </el-select>


      </div>

      <div class="right-item">
        <el-button type="primary" size="small" @click="queryUserInfo">查询</el-button>
        <el-button type="primary" size="small" @click="addUserInfo">新增部门</el-button>
      </div>
    </div>

    <el-table height="100%" :data="tableData" row-key="id" default-expand-all border>
      <el-table-column prop="orgName" label="部门名称" width="220"></el-table-column>
      <el-table-column label="操作" width="220" align="center">
        <template slot-scope="{ row }">
          <el-link type="primary" @click="editTableFun(row,'see')">详情</el-link>
          <el-link type="primary" @click="editTableFun(row)" v-btn-permission="'008006002001'">编辑</el-link>
          <el-link type="primary" @click="addChildUserInfo(row)" v-btn-permission="'008006002002'">添加子部门</el-link>
          <!-- <el-link type="danger" @click="deleteTableFun(row)">删除</el-link> -->
          <el-link v-if="row.status == 0" type="primary" @click="deleteTableFun(row)" v-btn-permission="'008006002003'">
            启用</el-link>
          <el-link v-else type="danger" @click="deleteTableFun(row)" v-btn-permission="'008006002004'">停用</el-link>
          <el-link v-if="row.orgType == 4" type="primary" @click="xzcode(row)">小程序登记码</el-link>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="orgType" label="部门类型" width="180">
        <template slot-scope="{ row }">
          <el-tag size="small">{{orgTypeFormatter(row.orgType)}}</el-tag>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="orgCode" label="机构编码" width="150"></el-table-column>
      <el-table-column align="center" prop="orgAlias" label="简称"></el-table-column>
      <el-table-column align="center" prop="address" label="地址"></el-table-column>
      <el-table-column align="center" prop="principal" label="负责人"></el-table-column>
      <el-table-column align="center" prop="linkPhone" label="负责电话"></el-table-column>
      <el-table-column align="center" prop="status" label="状态">
        <template slot-scope="{ row }">
          <el-tag size="small" v-if="row.status == 1">启用</el-tag>
          <el-tag size="small" type="danger" v-else-if="row.status == 0">停用</el-tag>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="seq" label="排序" width="70"></el-table-column>


      <el-table-column align="center" prop="homeIsolation" label="居家隔离业务" width="110">
        <template slot-scope="{ row }">
          <el-tag size="small" v-if="row.homeIsolation == 1">是</el-tag>
          <el-tag size="small" type="danger" v-else-if="row.homeIsolation == 0">否</el-tag>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="transshipmentDispatching" label="转运调度业务" width="110">
        <template slot-scope="{ row }">
          <el-tag size="small" v-if="row.transshipmentDispatching == 1">是</el-tag>
          <el-tag size="small" type="danger" v-else-if="row.transshipmentDispatching == 0">否</el-tag>
        </template>
      </el-table-column>

      <el-table-column align="center" prop="transshipmentTask" label="转运任务业务" width="110">
        <template slot-scope="{ row }">
          <el-tag size="small" v-if="row.transshipmentTask == 1">是</el-tag>
          <el-tag size="small" type="danger" v-else-if="row.transshipmentTask == 0">否</el-tag>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="centralizedIsolation" label="集中隔离业务" width="110">
        <template slot-scope="{ row }">
          <el-tag size="small" v-if="row.centralizedIsolation == 1">是</el-tag>
          <el-tag size="small" type="danger" v-else-if="row.centralizedIsolation == 0">否</el-tag>
        </template>
      </el-table-column>
    </el-table>

    <!-- <div class="content-page">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageObj.pageNumber"
        :page-size="pageObj.pageSize"
        :total="pageObj.total"
        :page-sizes="[10, 20, 30, 50, 100]"
        layout="total, sizes, prev, pager, next, jumper"
      ></el-pagination>
    </div> -->

    <!-- 部门编辑和新增  -->
    <el-dialog title="部门编辑" :visible.sync="dialogFormVisible" :close-on-click-modal="false" width="600px">
      <el-form :model="form" ref="addForm">
        <!-- {{form.parentId}} -->
        <el-form-item label="上级部门:" v-if="dialogFormVisibleType =='add'" label-width="120px" prop="parentId"
          :rules="{ required: true, message: '不能为空', trigger: 'blur'}">
          <el-cascader v-model="form.parentId" placeholder="输入关键字进行过滤" style="width:300px;" :options="cdList"
            :props="{ checkStrictly: true ,label:'orgName',value:'orgId'}" :show-all-levels="false" filterable>
          </el-cascader>
        </el-form-item>

        <el-form-item label="上级部门:" v-if="form.parentId !== '0' && dialogFormVisibleType !='add'" label-width="120px"
          prop="parentId">
          <el-cascader v-model="form.parentId" placeholder="输入关键字进行过滤" style="width:300px;" :options="cdList" disabled
            :props="{ checkStrictly: true ,label:'orgName',value:'orgId'}" :show-all-levels="false" filterable>
          </el-cascader>
        </el-form-item>

        <el-form-item label="部门名称:" label-width="120px" prop="orgName" placeholder="角色名称"
          :rules="{ required: true, message: '不能为空', trigger: 'blur'}">
          <el-input v-model="form.orgName" maxlength="10">
          </el-input>
        </el-form-item>
        <el-form-item label="简称:" label-width="120px" prop="orgAlias" placeholder="请输入名称"
          :rules="{ required: true, message: '不能为空', trigger: 'blur'}">
          <el-input v-model="form.orgAlias" maxlength="4">
          </el-input>
        </el-form-item>

        <el-form-item label="部门类型:" label-width="120px" prop="orgType"
          :rules="{ required: true, message: '不能为空', trigger: 'blur'}">
          <el-select class="select-width" size="medium" v-model="form.orgType" placeholder="全部">
            <el-option v-for="(item,v) in orgTypeList" :key="v" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="责任人:" label-width="120px" prop="principalId">
          <el-select class="select-width" @change="principalIdChange" filterable size="medium"
            v-model="form.principalId" placeholder="请选择">
            <el-option v-for="(item,v) in principalIdList" :key="v" :label="item.nickName" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="联系电话:" label-width="120px" prop="linkPhone" placeholder="">
          <el-input v-model="form.linkPhone">
          </el-input>
        </el-form-item>
        <el-form-item label="排序号:" label-width="120px" prop="seq"
          :rules="{ required: true, message: '不能为空', trigger: 'blur'}">
          <el-input-number v-model="form.seq" :min="1"></el-input-number>
        </el-form-item>
        <el-form-item label="地址:" label-width="120px" prop="address" placeholder="">
          <el-input v-model="form.address">
          </el-input>
        </el-form-item>
        <el-form-item label="经纬度(高德):" label-width="120px" prop="gdTuid" placeholder="">
          <el-input v-model="form.gdTuid">
          </el-input>
        </el-form-item>
        <el-form-item label="经纬度(百度):" label-width="120px" prop="bdTuid" placeholder="">
          <el-input v-model="form.bdTuid">
          </el-input>
        </el-form-item>
        <el-form-item label="状态:" label-width="120px" prop="status"
          :rules="{ required: true, message: '不能为空', trigger: 'blur'}">
          <el-radio-group v-model="form.status">
            <el-radio :label="1">启用</el-radio>
            <el-radio :label="0">停用</el-radio>、
          </el-radio-group>
        </el-form-item>

        <el-form-item label="集中隔离业务:" label-width="130px" prop="centralizedIsolation"
          :rules="{ required: true, message: '不能为空', trigger: 'blur'}">
          <el-radio-group v-model="form.centralizedIsolation">
            <el-radio :label="1">是</el-radio>
            <el-radio :label="0">否</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item label="居家隔离业务:" label-width="130px" prop="homeIsolation"
          :rules="{ required: true, message: '不能为空', trigger: 'blur'}">
          <el-radio-group v-model="form.homeIsolation">
            <el-radio :label="1">是</el-radio>
            <el-radio :label="0">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="转运调度业务:" label-width="130px" prop="transshipmentDispatching"
          :rules="{ required: true, message: '不能为空', trigger: 'blur'}">
          <el-radio-group v-model="form.transshipmentDispatching">
            <el-radio :label="1">是</el-radio>
            <el-radio :label="0">否</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item label="转运任务业务:" label-width="130px" prop="transshipmentTask"
          :rules="{ required: true, message: '不能为空', trigger: 'blur'}">
          <el-radio-group v-model="form.transshipmentTask">
            <el-radio :label="1">是</el-radio>
            <el-radio :label="0">否</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item label="是否为检测机构:" label-width="130px" prop="nucleicAcid"
          :rules="{ required: true, message: '不能为空', trigger: 'blur'}">
          <el-radio-group v-model="form.nucleicAcid">
            <el-radio :label="1">是</el-radio>
            <el-radio :label="0">否</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item label="签章照片:" label-width="130px" prop="sealUrl">
          <UploadFile ref="UploadFileRef" :srcProps="form.sealUrl" @uploadSuccess="uploadSuccess"
            @clearData="clearData" />
        </el-form-item>

        <el-form-item v-if="orgTypeShow" label="人群分类：" label-width="130px" prop="crowdTypeList">
          <el-select @change="crowdTypeChange" v-model="form.crowdTypeList" placeholder="选择人群分类" style="width:300px;"
            multiple>
            <el-option v-for="item in typepopSelect" :key="item.id" :label="item.ruleName" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>


      </el-form>
      <div slot="footer" v-if="dialogFormVisibleType !='see'" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogSubmit('addForm')">确 定</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import {
  getSysOrgTreeListByOrgCode,
  getSysOrgTreeList,
  mechanismInfo,
  mechanismSave,
  mechanismUpdate,
  mechanismUpdateStaus,
  getAllUserList,
  getMedicalOrgList,
  downloadRegQrCode,
  refreshRegLoginCodes
} from '../../api/SystemManagement/index.js';
import { getCrowdTypeList } from "@/api/RuleManagement/crowdAnalysis";
import {
  mapState
} from 'vuex';
import UploadFile from "../../components/UploadFile";
const formObj = { //表单
  parentId: "",
  orgAlias: "",
  orgType: "",
  orgName: "",
  principalId: "",
  principal: "",
  linkPhone: "",
  seq: "",
  address: "",
  gdTuid: "",
  bdTuid: "",
  status: 1,
  centralizedIsolation: 0, //是否集中隔离
  homeIsolation: 0, //是否居家隔离
  transshipmentDispatching: 0, //是否转运调度
  transshipmentTask: 0,  //是否转运任务
  nucleicAcid: 0, //是否检测机构
  sealUrl: "",
  peopleCode: "",
  crowdTypeId: "",
  crowdTypeList: [],
  peopleCodeList: [],
  pointOrgId: "",
};

const orgTypeList = [{
  value: "1",
  label: "社区卫生服务中心"
},
{
  value: "2",
  label: "二级医院"
},
{
  value: "3",
  label: "三级医院"
},
{
  value: "4",
  label: "隔离酒店"
},
{
  value: "5",
  label: "街道服务中心"
},
{
  value: "10",
  label: "其他"
},

];

export default {
  name: "mechanismManage",
  components: {
    UploadFile
  },
  data() {
    return {
      homelist: [{ value: 1, label: '居家隔离业务-是' }, { value: 0, label: '居家隔离业务-否' }],
      dispatthlist: [{ value: 1, label: '转运调度业务-是' }, { value: 0, label: '转运调度业务-否' }],
      assignlist: [{ value: 1, label: '转运任务业务-是' }, { value: 0, label: '转运任务业务-否' }],
      checkedOne: false,
      checkedTwo: false,
      checkedThree: false,
      queryForm: {
        orgName: '',
        orgType: '',
        homeIsolation: null, //居家隔离业务
        transshipmentDispatching: null,//转运调度业务
        transshipmentTask: null, //转运任务业务
        // centralizedIsolation:null, //集中隔离业务
      },
      tableData: [], //表单数据
      // 分页数据
      pageObj: {
        pageNumber: 1,
        pageSize: 20,
        total: 0,
      },
      dialogFormVisible: false,
      dialogFormVisibleType: 'add',
      form: formObj,
      orgTypeList: orgTypeList,
      parentList: [],
      typepopSelect: [],
      cdList: [],
      principalIdList: [],
      orgTypeShow: false,
      MedicalOrgList: [],
    }
  },

  computed: {
    ...mapState({
      isolationList: state => state.user.isolationList,
    }),
  },

  watch: {
    'form.orgType'(newVal) {
      console.log(111, newVal);
      if (newVal == 4) {
        this.getMedicalOrgListFun();
        this.orgTypeShow = true;
        this.form.centralizedIsolation = 1;
      } else {
        this.orgTypeShow = false;
        this.form.centralizedIsolation = 0;
      }
    },
    "form.address"(newValue, oldValue) {
      this.getBmap(newValue)
    },
  },

  created() {
    this.getCrowdTypeList();
    this.queryUserInfo();
    this.getAllUserListFun();
    this.getMedicalOrgListFun();
  },

  methods: {
    //获取百度经纬度
    getBmap(value) {
      this.$jsonp("https://api.map.baidu.com/geocoding/v3", {
        address: value,
        output: "json",
        ak: "K47zbc0avMITSmQCoPu3oU9Eg9HLsII4",
      })
        .then((json) => {
          console.log(22222222, json);
          this.form.bdTuid =
            json.result.location.lng + "," + json.result.location.lat;
          const currentAddressGdTuid = this.bMapTransqqMap(
            json.result.location.lng,
            json.result.location.lat
          );
          this.form.gdTuid =
            currentAddressGdTuid.lng + "," + currentAddressGdTuid.lat;
        })
        .catch((err) => {
          console.log(err);
          this.form.bdTuid = '';
          this.form.gdTuid = '';
        });
    },
    //百度转高德
    bMapTransqqMap(lng, lat) {
      const X_PI = (Math.PI * 3000.0) / 180.0;
      let x = lng - 0.0065;
      let y = lat - 0.006;
      let z = Math.sqrt(x * x + y * y) - 0.00002 * Math.sin(y * X_PI);
      let theta = Math.atan2(y, x) - 0.000003 * Math.cos(x * X_PI);
      let amap_lng = z * Math.cos(theta);
      let amap_lat = z * Math.sin(theta);
      return {
        lng: amap_lng,
        lat: amap_lat,
      };
    },
    // 获取二维码
    async xzcode(row) {
      let params = { id: row.id };
      console.log(11111, params);
      try {
        let { data } = await downloadRegQrCode({ params }, { responseType: "blob" });
        console.log(33333333333, data);
        let url = window.URL.createObjectURL(new Blob([data]));
        let link = document.createElement("a");
        link.style.display = "none";
        link.href = url;
        link.setAttribute("download", "二维码.png");
        document.body.appendChild(link);
        link.click();
      } catch (error) {

      }
    },
    // 重新生成二维码
    async dictionaryFun1(row) {
      let params = {
        id: row.id
      };
      let { data } = await refreshRegLoginCodes(params);
      let { code, msg } = data;
      if (code == 200) {
        this.$message.success("新的二维码生成成功！")
      } else {
        this.$message.error(msg)
      }
    },
    // 人群分类
    async getCrowdTypeList() {
      const { data } = await getCrowdTypeList();
      if (data.code == "200") {
        this.typepopSelect = data.data;
      }
    },
    // 驻点医疗机构
    getMedicalOrgListFun() {
      getMedicalOrgList().then(res => {
        let {
          code,
          data,
          msg
        } = res.data;
        if (code == 200) {
          this.MedicalOrgList = data;
        }
      });
    },
    orgTypeFormatter(orgType) {
      if (!orgType) return '';
      let obj = this.orgTypeList.find(item => {
        return item.value == orgType
      })
      return obj.label
    },
    principalIdChange(val) {
      console.log(val);
      let obj = this.principalIdList.find(item => {
        return item.id == val
      })
      this.form.principal = obj.nickName;
    },
    cdListFun() {
      // 根据机构权限获取机构树列表
      getSysOrgTreeListByOrgCode().then((res) => {
        let {code,data,msg} = res.data;
        if (code == 200) {
          this.cdList = data;
        }
      })
    },

    getAllUserListFun() {
      // 获取全部用户列表
      getAllUserList().then((res) => {
        let {
          code,
          data,
          msg
        } = res.data;
        if (code == 200) {
          this.principalIdList = data;
        }
      })
    },



    getUserInfo() {
      let params = Object.assign({}, this.queryForm, this.pageObj);
      // 获取列表机构树列表(机构全部信息-机构列表渲染使用)
      getSysOrgTreeList({
        params
      }).then((res) => {
        let {
          code,
          data,
          msg
        } = res.data;
        if (code == 200) {
          this.tableData = data;
          this.pageObj.total = Number(data.totalNumber);
        }
      })
    },
    //查询
    queryUserInfo() {
      this.getUserInfo(); //
      this.cdListFun();
    },
    //新增
    addUserInfo() {
      this.dialogFormVisible = true;
      this.dialogFormVisibleType = 'add';
      this.cdListFun();
      this.form = Object.assign({}, formObj);
    },
    addChildUserInfo(row) {
      this.dialogFormVisible = true;
      this.dialogFormVisibleType = 'add';
      this.cdListFun();
      this.form = Object.assign({}, formObj, {
        parentId: row.id
      });
    },
    //上传
    uploadSuccess(imgUrl) {
      this.form.sealUrl = imgUrl;
    },
    // 清除已上传的图片信息
    clearData() {
      this.form.sealUrl = "";
    },
    // orgTypeChange(e){
    //   if(e == 4){
    //     this.orgTypeShow = true;
    //   }
    // },
    //表单新增
    dialogSubmit(formName) {
      // if (!this.sealUrl) return this.$message.warning("请上传图片");

      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.submitFormFun();
        } else {
          return false;
        }
      });
    },
    submitFormFun() {
      if (Object.prototype.toString.call(this.form.parentId) === '[object Array]') {
        this.form.parentId = this.form.parentId[this.form.parentId.length - 1]
      }
      let params = Object.assign({}, this.form);
      params.peopleCode = params.peopleCodeList.toString();
      params.crowdTypeId = params.crowdTypeList.toString();
      console.log("🚀 ~ file: mechanismManage.vue ~ line 339 ~ submitFormFun ~ params", params)
      var API = this.dialogFormVisibleType == 'add' ? mechanismSave : mechanismUpdate;
      API(params).then((res) => {
        let {
          code,
          data,
          msg
        } = res.data;
        if (code == 200) {
          this.dialogFormVisible = false;
          this.$message({
            type: 'success',
            message: '保存成功!'
          });
          this.queryUserInfo();
          this.cdListFun()
        }
      })
    },

    //table操作
    //修改
    editTableFun(row, type) {
      console.log(row);
      this.dialogFormVisible = true;
      type ? this.dialogFormVisibleType = type : this.dialogFormVisibleType = 'set';
      mechanismInfo({
        id: row.id
      }).then((res) => {
        let {
          code,
          data,
          msg
        } = res.data;
        if (code == 200) {
          this.form = data;
          this.form.peopleCodeList = this.form.peopleCode ? this.form.peopleCode.split(',') : [];
          this.form.crowdTypeList = this.form.crowdTypeId ? this.form.crowdTypeId.split(',') : [];
        }
      })
    },
    //人群分类事件
    crowdTypeChange(crowdTypeIds) {
      let peopleCodes = [];
      this.typepopSelect.forEach(item=>{
        crowdTypeIds.forEach(crowdTypeId =>{
          if(crowdTypeId == item.id){
            peopleCodes.push(item.code);
          }
        })

      })
      this.form.peopleCodeList = peopleCodes;
      this.$forceUpdate();
    },

    //修改状态
    deleteTableFun(row) {
      this.$confirm(`确定要${row.status == 0 ? '启用' : '停用'}【${row.orgName}】吗？`, '操作确认', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let params = Object.assign({
          id: row.id,
          status: row.status == 1 ? 0 : 1
        });
        mechanismUpdateStaus(params).then((res) => {
          let {
            code,
            data,
            msg
          } = res.data;
          if (code == 200) {
            this.$message({
              type: 'success',
              message: '修改成功!'
            });
            this.queryUserInfo();
            this.cdListFun();
          }
        })
      })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消修改'
          });
        });
    },

    //分页变化
    handleSizeChange(val) {
      this.pageObj.pageSize = val;
      this.getUserInfo();
    },
    handleCurrentChange(val) {
      this.pageObj.pageNumber = val;
      this.getUserInfo();
    },


  }
}
</script>

<style scoped lang="scss">
@import "../../static/css/condition-filtrate.scss";

.main {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .el-table {
    overflow-y: auto;
  }
}

.content-page {
  margin-top: 10px;
  height: 40px;
  display: flex;
  justify-content: flex-end;
}

.el-link {
  margin-right: 10px;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>